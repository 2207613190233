import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Link,
  List,
  ListItem,
  ListItemButton,
  Paper,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  AccessTime,
  Description,
  ExpandLess,
  ExpandMore,
  LocationOn,
  Person,
  Image,
  LocalPostOffice,
  CheckCircle,
} from '@mui/icons-material';

import { useSnackbar } from 'notistack';
import { useLoaderData, useRevalidator } from 'react-router-dom';
import api from 'api';
import { COLORS, ORDER_STATUS } from 'utils/constants';
import { format } from 'date-fns';
import { useVendor } from 'contexts/VendorContext';
import OrderStatusChip from 'components/OrderStatusChip';
import OrderMap from './components/OrderMap';
import AsyncButton from 'components/AsyncButton';
import EzModal from 'components/EzModal';

const OrderInfo = () => {
  const order = useLoaderData();
  const { washVendor } = useVendor();
  const revalidator = useRevalidator();
  const { enqueueSnackbar } = useSnackbar();
  const [showMap, setShowMap] = useState(order.status === ORDER_STATUS.IN_ROUTE);
  const [notes, setNotes] = useState('');
  const [cancelModal, setCancelModal] = useState(false);
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));

  useEffect(() => {
    setShowMap(order.status === ORDER_STATUS.IN_ROUTE);
  }, [order.status]);

  const handleCloseModal = () => {
    setNotes('');
    setCancelModal(false);
  };

  const handleLeaveOrder = async () => {
    if (!notes) {
      enqueueSnackbar('Please tell us why you want to leave this order', {
        variant: 'error',
      });
      return;
    }
    try {
      await api.put(`/wash-vendor-app/${washVendor._id}/order/${order._id}/leave`, { notes });
      enqueueSnackbar('You have been successfully unassigned from the order', {
        variant: 'success',
      });
      setCancelModal(false);
      revalidator.revalidate();
    } catch (e) {
      enqueueSnackbar(e.response?.data?.message ?? 'Something went wrong', { variant: 'error' });
    }
  };

  const handleCancelOrder = async () => {
    if (!notes) {
      enqueueSnackbar('Please tell us why you want to cancel this order', {
        variant: 'error',
      });
      return;
    }
    try {
      await api.put(`/wash-vendor-app/${washVendor._id}/order/${order._id}/cancel`, { notes });
      enqueueSnackbar('Order cancelled successfully', { variant: 'success' });
      setCancelModal(false);
      revalidator.revalidate();
    } catch (e) {
      enqueueSnackbar(e.response?.data?.message ?? 'Something went wrong', { variant: 'error' });
    }
  };

  const DetailItem = ({ icon, label, children }) => {
    return (
      <Box sx={{ width: '800px', display: 'flex' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mr: 1, color: COLORS.EzWashPrimay }}>
          {icon && icon}
        </Box>
        {isDesktop && label && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography fontWeight={500} sx={{ width: '200px' }}>
              {label}
            </Typography>
          </Box>
        )}
        <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>{children}</Box>
      </Box>
    );
  };

  return (
    <Paper sx={{ p: 1, borderRadius: '15px', mt: 1 }}>
      <Box sx={{ mt: 1, ml: 1, display: 'flex', justifyContent: 'center' }}>
        <OrderStatusChip status={order.status} />
      </Box>
      <List
        sx={{
          paddingBottom: 0,
          paddingTop: 0,
          mt: 1,
        }}
      >
        {order.serviceDate ? (
          <ListItem sx={{ pl: 1 }}>
            <DetailItem icon={<CheckCircle />} label="Completed On">
              <Typography sx={{ ml: 1 }}>
                {format(new Date(order.serviceDate), 'MM/dd/yy hh:mm a')}
              </Typography>
            </DetailItem>
          </ListItem>
        ) : null}

        <ListItem sx={{ pl: 1 }}>
          <DetailItem icon={<Person />} label="Consumer">
            <Typography sx={{ ml: 1 }}>
              {order.userId.firstName} {order.userId.lastName}
            </Typography>
            <Link sx={{ ml: 1 }} href={`tel:${order.userId.phone}`}>
              {order.userId.phone}
            </Link>
          </DetailItem>
        </ListItem>

        <ListItemButton sx={{ pl: 1 }} onClick={() => setShowMap(v => !v)}>
          <ListItem disablePadding>
            <DetailItem icon={<LocationOn />} label="Location">
              <Link sx={{ ml: 1, textDecoration: 'none' }}>
                {order.address.line1}, {order.address.city}, {order.address.zipcode}
              </Link>
            </DetailItem>
          </ListItem>
          {showMap ? <ExpandMore /> : <ExpandLess />}
        </ListItemButton>

        {showMap ? (
          <ListItem disablePadding>
            <div style={{ width: '100%' }}>
              <OrderMap />
            </div>
          </ListItem>
        ) : null}

        <ListItem sx={{ pl: 1 }}>
          <DetailItem icon={<AccessTime />} label="Time Windows">
            <Typography sx={{ ml: 1 }}>
              {format(new Date(order.startTime), 'hh:mm a')} {' - '}
              {format(new Date(order.endTime), 'hh:mm a')}
            </Typography>
          </DetailItem>
        </ListItem>

        {order.adminNotes?.length > 0 ? (
          <ListItem sx={{ pl: 1 }}>
            <DetailItem icon={<Description />} label="Description">
              <Stack ml={1} color={COLORS.Steel}>
                {order.adminNotes.map((note, index) => (
                  <Typography key={index} variant="body1">
                    {note}
                  </Typography>
                ))}
              </Stack>
            </DetailItem>
          </ListItem>
        ) : null}

        {order.deliveryInstructions?.length > 0 ? (
          <ListItem sx={{ pl: 1 }}>
            <DetailItem icon={<LocalPostOffice />} label="Delivery Notes">
              <Typography variant="body1" sx={{ ml: 1, color: COLORS.Steel }}>
                {order.deliveryInstructions}
              </Typography>
            </DetailItem>
          </ListItem>
        ) : null}

        {order.deliveryImages?.length > 0 ? (
          <ListItem sx={{ pl: 1 }}>
            <DetailItem icon={<Image />} label="Pictures">
              <Box>
                {order.deliveryImages.map(imgSrc => (
                  <a
                    key={imgSrc.path}
                    href={imgSrc.path}
                    target="_blank"
                    style={{ marginLeft: '10px' }}
                  >
                    <img src={imgSrc.path} style={{ width: 75, height: 75, borderRadius: 10 }} />
                  </a>
                ))}
              </Box>
            </DetailItem>
          </ListItem>
        ) : null}

        {ORDER_STATUS.UNFINISHED.includes(order.status) ? (
          <Box
            sx={{
              margin: 1,
              flex: 1,
              display: 'flex',
              justifyContent: { xs: 'center', md: 'flex-end' },
              textAlign: { xs: 'center', md: 'right' },
            }}
          >
            <AsyncButton variant="outlined" onClick={() => setCancelModal(true)}>
              I have an issue
            </AsyncButton>
          </Box>
        ) : null}
      </List>

      <EzModal isOpen={cancelModal} onClose={handleCloseModal}>
        <Typography variant="h6">I Have An Issue</Typography>
        <TextField
          label="Notes"
          placeholder="Tell us the problem with this order"
          multiline
          rows={3}
          variant="outlined"
          fullWidth
          value={notes}
          onChange={e => setNotes(e.target.value)}
          sx={{ mt: 1, mb: 2 }}
        />
        <Stack spacing={1}>
          <Button variant="outlined" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="contained" onClick={handleCancelOrder}>
            Cancel Order
          </Button>
          <Button variant="contained" onClick={handleLeaveOrder}>
            {'Leave Order'}
          </Button>
        </Stack>
      </EzModal>
    </Paper>
  );
};
export default OrderInfo;
