import React from 'react';
import { AppBar, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Header = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <AppBar>
      <Toolbar>
        <img
          src={require('../../assets/ezwash_logomark.png')}
          width={50}
          style={{ marginRight: 15 }}
        />

        {!isMobile && (
          <Typography component="div" fontSize={23} fontWeight={800} sx={{ marginRight: 5 }}>
            EZWASH
          </Typography>
        )}

        <Typography fontSize={23} fontWeight={500}>
          {children}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
