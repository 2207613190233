import React from 'react';
import { Map, Marker } from '@vis.gl/react-google-maps';
import { Box, Button } from '@mui/material';
import { Navigation } from '@mui/icons-material';
import { useLoaderData } from 'react-router-dom';

const OrderMap = () => {
  const order = useLoaderData();
  const center = { lat: order.latitude, lng: order.longitude };

  const handleNavigate = async () => {
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${center.lat},${center.lng}`,
      '_blank',
    );
  };

  return (
    <div>
      <Box sx={{ height: '20vh', width: '100%' }}>
        <Map defaultZoom={13} defaultCenter={center} gestureHandling={'greedy'} disableDefaultUI>
          <Marker position={center} />
        </Map>
      </Box>
      <Button
        variant="contained"
        sx={{
          width: '100%',
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
        onClick={handleNavigate}
      >
        Navigate <Navigation sx={{ fontSize: 18, marginLeft: '3px' }} />
      </Button>
    </div>
  );
};
export default OrderMap;
