import React, { useState } from 'react';
import { AppBar, Box, Button, CircularProgress, Toolbar, Typography } from '@mui/material';
import { COLORS } from 'utils/constants';

const AsyncButton = ({ children, disabled, onClick, ...rest }) => {
  const [isLoading, setLoading] = useState(false);
  const handleClick = async () => {
    if (!onClick) return;
    setLoading(true);
    try {
      await onClick();
    } catch (e) {
      setLoading(false);
      throw e;
    }
    setLoading(false);
  };
  return (
    <Button {...rest} disabled={isLoading || disabled} onClick={handleClick}>
      {isLoading ? <CircularProgress /> : children}
    </Button>
  );
};
export default AsyncButton;
