import React from 'react';
import { Alert, Box, Typography } from '@mui/material';
import { useLoaderData } from 'react-router-dom';
import { ORDER_STATUS } from 'utils/constants';

import OrderPendingView from './components/OrderPendingView';
import OrderInRouteView from './components/OrderInRouteView';
import OrderVehiclesView from './components/OrderVehiclesView';

const OrderSubmitForm = () => {
  return (
    <Box sx={{ mt: 2 }}>
      <ViewSwitcher />
    </Box>
  );
};
export default OrderSubmitForm;

const ViewSwitcher = () => {
  const order = useLoaderData();

  if (!order)
    return (
      <Alert severity="error">
        <Typography>Order not found</Typography>
      </Alert>
    );

  switch (order.status) {
    case ORDER_STATUS.PENDING:
      return <OrderPendingView />;
    case ORDER_STATUS.IN_ROUTE:
      return <OrderInRouteView />;
    case ORDER_STATUS.ARRIVED:
    case ORDER_STATUS.PARTIALLY_SERVICED:
    case ORDER_STATUS.SERVICED:
    case ORDER_STATUS.CANCEL_ADMIN:
    case ORDER_STATUS.CANCEL_DRIVER:
      return <OrderVehiclesView />;
    default:
      return (
        <Alert severity="danger">
          <Typography>
            Order status: <b>{order.status}</b> unrecognized
          </Typography>
        </Alert>
      );
  }
};
