import * as React from 'react';
import {
  createBrowserRouter,
  isRouteErrorResponse,
  RouterProvider,
  useRouteError,
} from 'react-router-dom';
import YourOrders from './pages/YourOrders';
import OrderDetails from './pages/OrderDetails';
import Layout from './pages/Layout';
import api from './api';
import { format } from 'date-fns';
import { APIProvider } from '@vis.gl/react-google-maps';
import { VendorProvider } from 'contexts/VendorContext';
import { SnackbarProvider } from 'notistack';

const router = createBrowserRouter([
  {
    path: '/:vendorId',
    Component: Layout,
    loader: async ({ params: { vendorId } }) => {
      const resp = await api.get(`wash-vendor-app/${vendorId}`);
      return resp.data;
    },
    errorElement: <RootBoundary />,
    children: [
      {
        Component: YourOrders,
        index: true,
        loader: async ({ params: { vendorId } }) => {
          const { data } = await api.get(`wash-vendor-app/${vendorId}/orders`);

          data.unfinishedOrders = Object.entries(
            (data?.unfinishedOrders || []).reduce((acc, curr) => {
              const date = format(new Date(curr.startTime), 'MM/dd');
              if (!acc[date]) acc[date] = [];
              acc[date].push(curr);
              return acc;
            }, {}),
          ).map(([day, orders]) => ({ day, orders }));

          return data;
        },
      },
      {
        path: 'o/:orderId',
        Component: OrderDetails,
        loader: async ({ params: { vendorId, orderId } }) => {
          const resp = await api.get(`wash-vendor-app/${vendorId}/order/${orderId}`);
          return resp.data;
        },
      },
    ],
  },
]);

export default function App() {
  return (
    <APIProvider apiKey={process.env.REACT_APP_PLACES_API_KEY}>
      <VendorProvider>
        <SnackbarProvider
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          autoHideDuration={3000}
        >
          <RouterProvider router={router} />
        </SnackbarProvider>
      </VendorProvider>
    </APIProvider>
  );
}

function RootBoundary() {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <div>This page doesn't exist!</div>;
    }

    if (error.status === 401) {
      return <div>You aren't authorized to see this</div>;
    }

    if (error.status === 503) {
      return <div>Looks like our API is down</div>;
    }

    if (error.status === 418) {
      return <div>🫖</div>;
    }
  }

  return <div>Error: {error.message}</div>;
}
