import React, { useEffect } from 'react';
import { Alert, Box, CircularProgress, Container } from '@mui/material';
import { Link, Outlet, useLoaderData, useParams } from 'react-router-dom';
import { useVendor } from 'contexts/VendorContext';

const Layout = () => {
  const vendorLoaded = useLoaderData();
  const { washVendor, setVendor } = useVendor();

  useEffect(() => {
    setVendor(vendorLoaded);
  }, []);
  return (
    <Container
      style={{ paddingTop: 75, paddingBottom: 20, backgroundColor: '#f3f3f7', minHeight: '100vh' }}
    >
      {washVendor === undefined ? (
        <CircularProgress />
      ) : washVendor === null ? (
        <Alert severity="error">Your account does not exist or it is currently inactive</Alert>
      ) : (
        <Outlet />
      )}
    </Container>
  );
};
export default Layout;
