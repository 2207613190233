import React from 'react';
import { Alert, Breadcrumbs, CircularProgress, Link, Paper, Typography } from '@mui/material';
import { useLoaderData } from 'react-router-dom';
import Header from 'components/Header';
import OrderSubmitForm from './components/OrderSubmitForm';
import { useVendor } from 'contexts/VendorContext';
import OrderInfo from './components/OrderInfo';

const OrderDetails = () => {
  const { washVendor } = useVendor();
  const order = useLoaderData();
  if (!washVendor) return <CircularProgress />;

  if (!order)
    return (
      <Alert severity="danger">
        <Typography>Order not found</Typography>
      </Alert>
    );

  return (
    <div>
      <Header>{washVendor.name}</Header>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href={`/${washVendor._id}`}>
          Your Orders
        </Link>
        <Typography sx={{ color: 'text.primary' }}>{order.address.line1}</Typography>
      </Breadcrumbs>
      <OrderInfo />
      <OrderSubmitForm />
    </div>
  );
};
export default OrderDetails;
