import { Box, Modal } from '@mui/material';

const EzModal = ({ isOpen = false, onClose = () => {}, children }) => {
  if (!children) return <></>;

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <Box
          sx={{
            padding: 2,
            backgroundColor: 'white',
            borderRadius: 2,
            maxWidth: { xs: 400, md: 600 },
            width: '90%',
          }}
        >
          {children}
        </Box>
      </Box>
    </Modal>
  );
};
export default EzModal;
