import React, { useState } from 'react';
import {
  Alert,
  Breadcrumbs,
  Divider,
  Link,
  List,
  ListItem,
  ListItemButton,
  Typography,
  Tabs,
  Tab,
  Box,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useLoaderData, useNavigate } from 'react-router-dom';
import Header from 'components/Header';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { format } from 'date-fns';
import { useVendor } from 'contexts/VendorContext';
import OrderStatusChip from 'components/OrderStatusChip';
import { COLORS } from 'utils/constants';

const YourOrders = () => {
  const { washVendor } = useVendor();
  const orders = useLoaderData();
  const today = format(new Date(), 'MM/dd');
  const [tabValue, setTabValue] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleTabChange = (e, newValue) => {
    setTabValue(newValue);
  };

  const renderUnfinishedOrders = groupedOrders => {
    if (groupedOrders.length === 0) {
      return (
        <Alert>
          <Typography>You have no new orders at the moment</Typography>
        </Alert>
      );
    }

    return groupedOrders.map(({ day, orders }) => {
      const isToday = today === day;
      return (
        <div key={day}>
          <Typography
            fontSize={15}
            sx={{
              paddingTop: 3,
              paddingBottom: 1,
              color: isToday ? COLORS.EzWashPrimay : COLORS.Steel,
              fontWeight: isToday ? '600' : '500',
              letterSpacing: 0.5,
            }}
          >
            {isToday ? 'TODAY' : day}
          </Typography>
          <List
            sx={{
              bgcolor: 'background.paper',
              paddingBottom: 0,
              paddingTop: 0,
            }}
            component="nav"
          >
            {orders.map(order => (
              <OrderListItem order={order} />
            ))}
          </List>
        </div>
      );
    });
  };

  const renderFinishedOrders = orders => {
    if (orders.length === 0) {
      return (
        <Alert severity="warning">
          <Typography>No recent completed orders were found.</Typography>
        </Alert>
      );
    }

    return (
      <List
        sx={{
          bgcolor: 'background.paper',
          paddingBottom: 0,
          paddingTop: 0,
        }}
        component="nav"
      >
        {orders.map(order => (
          <OrderListItem order={order} />
        ))}
      </List>
    );
  };

  return (
    <div>
      <Header>{washVendor.name}</Header>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" href={`/${washVendor._id}`}>
          <Typography fontSize={22} fontWeight={500} color="#234491">
            Your Orders
          </Typography>
        </Link>
      </Breadcrumbs>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 2 }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="order tabs"
          centered={isMobile}
        >
          <Tab label="Pending Orders" />
          <Tab label="Last Completed Orders" />
        </Tabs>
      </Box>
      <Box sx={{ mt: 2 }}>
        {tabValue === 0
          ? renderUnfinishedOrders(orders.unfinishedOrders)
          : renderFinishedOrders(orders.finishedOrders)}
      </Box>
    </div>
  );
};

export default YourOrders;

const OrderListItem = ({ order }) => {
  const navigate = useNavigate();

  return [
    <ListItemButton
      key={order._id}
      onClick={() => {
        navigate(`o/${order._id}`);
      }}
    >
      <ListItem
        disablePadding
        sx={{
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Typography fontWeight={'500'}>
          {order.address.line1}, {order.address.city}
        </Typography>
        <Typography>
          {order.userId.firstName} {order.userId.lastName}
        </Typography>
      </ListItem>
      <ListItem
        sx={{
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <OrderStatusChip status={order.status} />
        <Typography>
          {format(new Date(order.startTime), 'hh:mm a')} {' - '}
          {format(new Date(order.endTime), 'hh:mm a')}
        </Typography>
        <Typography>
          {order.services.length} vehicle{order.services.length > 1 ? 's' : ''}
        </Typography>
      </ListItem>
      <ChevronRightIcon />
    </ListItemButton>,
    <Divider component="li" key={`divider-${order._id}`} />,
  ];
};
