import React from 'react';
import { Box } from '@mui/material';
import { ORDER_STATUS_LABEL, STATUS_COLORS } from 'utils/constants';

const OrderStatusChip = ({ status }) => {
  return (
    <Box
      display={'inline-block'}
      sx={{
        padding: '7px 10px 7px 10px',
        minWidth: '140px',
        borderRadius: '50px',
        backgroundColor: STATUS_COLORS.CONTAINER(status),
        textAlign: 'center',
        boxShadow: '1px 3px 10px 1px rgba(0,0,0,0.05)',
      }}
    >
      <Box sx={{ color: STATUS_COLORS.TEXT(status), fontWeight: '500', fontSize: 14 }}>
        {ORDER_STATUS_LABEL[status]}
      </Box>
    </Box>
  );
};
export default OrderStatusChip;
