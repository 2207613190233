import React, { useState } from 'react';
import { Box, Chip, Grid2, Typography, Modal, TextField, Button } from '@mui/material';
import { useLoaderData, useNavigate, useRevalidator } from 'react-router-dom';
import api from 'api';
import AsyncButton from 'components/AsyncButton';
import EzModal from 'components/EzModal';
import { useVendor } from 'contexts/VendorContext';
import { useSnackbar } from 'notistack';
import TakePicture from 'components/TakePicture';
import {
  COLORS,
  ORDER_SERVICE_STATUS,
  ORDER_SERVICE_STATUS_CHIP_COLOR,
  ORDER_SERVICE_STATUS_LABEL,
  ORDER_STATUS,
} from 'utils/constants';

const OrderService = ({ service, isCompleted = false }) => {
  const [notes, setNotes] = useState('');
  const [cancelModal, setCancelModal] = useState(false);
  const order = useLoaderData();
  const { enqueueSnackbar } = useSnackbar();
  const { washVendor } = useVendor();
  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const vehicle = service.vehicle;

  const handleCloseModal = () => {
    setNotes('');
    setCancelModal(false);
  };

  const handleComplete = async serviceStatus => {
    try {
      const resp = await api.put(
        `/wash-vendor-app/${washVendor._id}/order/${order._id}/service/${service._id}/complete`,
        {
          serviceStatus,
          notes,
        },
      );

      handleCloseModal();

      if (ORDER_STATUS.UNFINISHED.includes(resp.data.status)) {
        enqueueSnackbar(`Vehicle ${serviceStatus} successfully`, { variant: 'success' });
        revalidator.revalidate();
      } else {
        enqueueSnackbar('Order finished successfully', { variant: 'success' });
        navigate(`/${washVendor._id}`, { replace: true });
      }
    } catch (e) {
      enqueueSnackbar(e.response?.data?.message ?? 'Something went wrong', { variant: 'error' });
    }
  };

  const handleUpload = async (imageField, img) => {
    const formData = new FormData();
    formData.append('image', img);
    try {
      await api.put(
        `/wash-vendor-app/${washVendor._id}/order/${order._id}/service/${service._id}/image/${imageField}`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );

      revalidator.revalidate();
    } catch (e) {
      enqueueSnackbar(e.response?.data?.message ?? 'Something went wrong', { variant: 'error' });
    }
  };

  return (
    <Box>
      <Grid2
        container
        sx={{
          padding: 1,
          justifyContent: { xs: 'space-between', md: 'flex-start' },
        }}
      >
        <Grid2 item sx={{ width: { md: '100px' } }}>
          <Typography variant="body1" fontWeight={500}>
            Plan
          </Typography>
        </Grid2>
        <Grid2 item>
          <Chip label={service.washPlan.toUpperCase()} color="info" size="small" />
        </Grid2>
      </Grid2>

      <Grid2
        container
        sx={{
          padding: 1,
          justifyContent: { xs: 'space-between', md: 'flex-start' },
        }}
      >
        <Grid2 item sx={{ width: { md: '100px' } }}>
          <Typography variant="body1" fontWeight={500}>
            Plate
          </Typography>
        </Grid2>
        <Grid2 item>
          <Box
            sx={{
              fontWeight: '600',
              letterSpacing: 3,
              border: 'solid 1px #666',
              textAlign: 'center',
              borderRadius: '6px',
              backgroundColor: '#EEE',
              paddingX: 1,
            }}
          >
            {vehicle.plate}
          </Box>
        </Grid2>
      </Grid2>

      <Grid2
        container
        sx={{
          padding: 1,
          justifyContent: { xs: 'space-between', md: 'flex-start' },
        }}
      >
        <Grid2 item sx={{ width: { md: '100px' } }}>
          <Typography variant="body1" fontWeight={500}>
            Color
          </Typography>
        </Grid2>
        <Grid2 item>
          <Typography fontWeight={500}>{vehicle.color}</Typography>
        </Grid2>
      </Grid2>

      <Grid2
        container
        sx={{
          padding: 1,
          justifyContent: { xs: 'space-between', md: 'flex-start' },
        }}
      >
        <Grid2 item sx={{ width: { md: '100px' } }}>
          <Typography variant="body1" fontWeight={500}>
            Status
          </Typography>
        </Grid2>
        <Grid2 item>
          <Chip
            label={ORDER_SERVICE_STATUS_LABEL[service.status]}
            color={ORDER_SERVICE_STATUS_CHIP_COLOR[service.status]}
            size="small"
          />
        </Grid2>
      </Grid2>

      {service.cancellationNotes ? (
        <Grid2
          container
          sx={{
            padding: 1,
            justifyContent: { xs: 'space-between', md: 'flex-start' },
          }}
        >
          <Grid2 item sx={{ width: { md: '100px' } }}>
            <Typography variant="body1" fontWeight={500}>
              Notes
            </Typography>
          </Grid2>
          <Grid2 item>
            <Typography variant="body1" color={COLORS.Steel}>
              {service.cancellationNotes}
            </Typography>
          </Grid2>
        </Grid2>
      ) : null}

      <Grid2 container sx={{ overflowX: 'auto', mt: 2 }} spacing={1}>
        {service.imagesBefore.map((img, i) => (
          <Grid2 item key={'imgBefore' + i} xs="auto">
            <TakePicture value={img} />
          </Grid2>
        ))}

        {!isCompleted && (
          <Grid2 item xs="auto">
            <TakePicture
              label="BEFORE"
              onChange={async img => await handleUpload('imagesBefore', img)}
            />
          </Grid2>
        )}
      </Grid2>

      <Grid2 container sx={{ overflowX: 'auto', mt: 2 }} spacing={1}>
        {service.imagesAfter.map((img, i) => (
          <Grid2 item key={'imgAfter' + i} xs="auto">
            <TakePicture value={img} />
          </Grid2>
        ))}
        {!isCompleted && (
          <Grid2 item xs="auto">
            <TakePicture
              label="AFTER"
              onChange={async img => await handleUpload('imagesAfter', img)}
            />
          </Grid2>
        )}
      </Grid2>

      {!isCompleted && ORDER_STATUS.UNFINISHED.includes(order.status) ? (
        <Grid2
          container
          spacing={2}
          sx={{
            marginTop: 5,
            justifyContent: { xs: 'center', md: 'flex-end' },
          }}
        >
          <Grid2 item>
            <AsyncButton variant="outlined" onClick={() => setCancelModal(true)}>
              CANCEL
            </AsyncButton>
          </Grid2>
          <Grid2 item>
            <AsyncButton
              variant="contained"
              onClick={handleComplete.bind(null, ORDER_SERVICE_STATUS.SERVICED)}
            >
              COMPLETE
            </AsyncButton>
          </Grid2>
        </Grid2>
      ) : null}

      <EzModal isOpen={cancelModal} onClose={handleCloseModal}>
        <Typography variant="h6">Cancel Service</Typography>
        <TextField
          label="Notes (optional)"
          placeholder="Tell us why you can't wash this vehicle"
          multiline
          rows={3}
          variant="outlined"
          fullWidth
          value={notes}
          onChange={e => setNotes(e.target.value)}
          sx={{ mt: 1, mb: 2 }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={handleCloseModal} sx={{ marginRight: 1 }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleComplete.bind(null, ORDER_SERVICE_STATUS.CANCELED)}
          >
            Confirm
          </Button>
        </Box>
      </EzModal>
    </Box>
  );
};

export default OrderService;
