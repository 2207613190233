import React, { useState } from 'react';
import {
  Box,
  Tab,
  Tabs,
  Typography,
  List,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Alert,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { useLoaderData } from 'react-router-dom';
import OrderService from './OrderService';
import { COLORS } from 'utils/constants';

const OrderVehiclesView = () => {
  const order = useLoaderData();
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const services = order.services.reduce((acc, curr) => {
    const vehicleData = order.userId.vehicles.find(v => v._id === curr.vehicle);
    if (vehicleData) acc.push({ ...curr, vehicle: vehicleData });
    return acc;
  }, []);

  const { pendingServices, completedServices } = services.reduce(
    (acc, service) => {
      if (service.status === 'unserviced') acc.pendingServices.push(service);
      else acc.completedServices.push(service);
      return acc;
    },
    { pendingServices: [], completedServices: [] },
  );

  if (!services.length) return <></>;

  return (
    <Paper
      sx={{
        p: 1,
        borderRadius: '15px',
        mb: 1,
        'table tr .first': { minWidth: 50, paddingRight: '5px' },
      }}
    >
      <Box sx={{ p: 1 }}>
        <Tabs value={tabIndex} onChange={handleTabChange} sx={{ mb: 1 }}>
          <Tab label="Pending" />
          <Tab label="Completed" />
        </Tabs>

        {tabIndex === 0 &&
          (pendingServices.length ? (
            <List>
              {pendingServices.map(service => (
                <Accordion key={service._id} sx={{ backgroundColor: '#f6f8fa' }}>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography fontWeight={500} color={COLORS.EzWashPrimay}>
                      {service.vehicle.make} {service.vehicle.model}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <OrderService service={service} />
                  </AccordionDetails>
                </Accordion>
              ))}
            </List>
          ) : (
            <Alert sx={{ m: 2 }}>
              <Typography>No pending services found</Typography>
            </Alert>
          ))}

        {tabIndex === 1 &&
          (completedServices.length ? (
            <List>
              {completedServices.map(service => (
                <Accordion key={service._id} sx={{ backgroundColor: '#f6f8fa' }}>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography fontWeight={500} color={COLORS.EzWashPrimay}>
                      {service.vehicle.make} {service.vehicle.model}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <OrderService service={service} isCompleted />
                  </AccordionDetails>
                </Accordion>
              ))}
            </List>
          ) : (
            <Alert sx={{ m: 2 }} severity="info">
              <Typography>No completed services found</Typography>
            </Alert>
          ))}
      </Box>
    </Paper>
  );
};

export default OrderVehiclesView;
