export const COLORS = {
  //Brand Colors
  EzPrimary: '#F75010', //Brand Official Color
  EzSecondary: '#28285C',
  EzLightSecondary: '#c1c1e8',
  EzOrange: '#FF6600',
  EzLightOrange: '#FFF3EA',
  EzBlue: '#120752',
  EzWashPrimay: '#32326e',
  EzClear: '#E5E6EC',
  EzYellow: '#FFB300',
  EzDarkYellow: '#ed9700',
  EzLightYellow: '#FBF5E9', //used as background in some selected items
  PaleOrange: '#FFF5EC',

  // Monochromatic Shades (lighter as you scroll down).
  DarkGray: '#80859F',
  Gray: '#888',
  MediumGray: '#D9DAE3',
  Steel: '#666D8E', // Input Text, has shade of blue
  LightGray: '#A5A5A5',
  Thistle: '#BCBFD4', // Used for Input Borders
  Gainsboro: '#D9DAE3',
  Diego: '#EEE', // Very light Gray
  Silver: '#F4F5FA', // '#B4B4B4' Lighter than Gray
  Overlay: '#37374FCC', // Used for modal backgrounds (Opacity)
  AlmostWhite: '#F7F7F7',

  //Statuses
  Red: '#e32822', //Error Outline for Inputs
  LightRed: '#F9453F', //Error Outline for Inputs
  Pink: '#FFBDBD', //Error Container Very Light Red
  Green: '#40C833',
  veryLightGreen: '#EAFFE6',

  //3rdParty
  Facebook: '#4267B2',
  Google: '#DB4437',
  Paypal: '#CDE8FD',

  //Basic
  Black: '#000',
  White: '#FFF',
  Yellow: '#FFDA2D',
  Gold: '#FAC135',

  //Custom
  Warning: '#D9AC4C',

  //Fuel Levels
  LightGreen: '#3CD266',
};

export const ORDER_STATUS = {
  PENDING: 'pending',
  IN_ROUTE: 'in_route',
  ARRIVED: 'arrived',
  SERVICED: 'serviced',
  DELETED: 'deleted',
  PARTIALLY_SERVICED: 'partially_serviced',
  CANCEL_DRIVER: 'cancel_driver',
  CANCEL_CONSUMER: 'cancel_consumer',
  CANCEL_ADMIN: 'cancel_admin',
  PAYMENT_FAILED: 'payment_failed',
  UNFINISHED: ['pending', 'in_route', 'arrived', 'partially_serviced'],
};

export const ORDER_STATUS_LABEL = {
  [ORDER_STATUS.PENDING]: 'PENDING',
  [ORDER_STATUS.IN_ROUTE]: 'IN ROUTE',
  [ORDER_STATUS.ARRIVED]: 'ARRIVED',
  [ORDER_STATUS.PARTIALLY_SERVICED]: 'PARTIALLY SERVICED',
  [ORDER_STATUS.SERVICED]: 'SERVICED',
  [ORDER_STATUS.CANCEL_DRIVER]: 'CANCELLED',
  [ORDER_STATUS.CANCEL_CONSUMER]: 'CANCELLED',
  [ORDER_STATUS.CANCEL_ADMIN]: 'CANCELLED',
  [ORDER_STATUS.PAYMENT_FAILED]: 'PAYMENT FAILED',
  [ORDER_STATUS.DELETED]: 'DELETED',
};

export const STATUS_COLORS = {
  CONTAINER: status => {
    switch (status) {
      case ORDER_STATUS.PENDING:
        return COLORS.EzLightOrange;
      case ORDER_STATUS.IN_ROUTE:
      case ORDER_STATUS.ARRIVED:
        return COLORS.EzLightSecondary;
      case ORDER_STATUS.PARTIALLY_SERVICED:
        return COLORS.EzYellow;
      case ORDER_STATUS.SERVICED:
        return COLORS.veryLightGreen;
      case ORDER_STATUS.CANCEL_ADMIN:
      case ORDER_STATUS.CANCEL_CONSUMER:
      case ORDER_STATUS.CANCEL_DRIVER:
      case ORDER_STATUS.PAYMENT_FAILED:
      case ORDER_STATUS.DELETED:
      default:
        return COLORS.Pink;
    }
  },

  TEXT: status => {
    switch (status) {
      case ORDER_STATUS.PENDING:
        return COLORS.EzPrimary;
      case ORDER_STATUS.IN_ROUTE:
      case ORDER_STATUS.ARRIVED:
        return COLORS.EzWashPrimay;
      case ORDER_STATUS.PARTIALLY_SERVICED:
        return COLORS.White;
      case ORDER_STATUS.SERVICED:
        return COLORS.Green;
      case ORDER_STATUS.CANCEL_ADMIN:
      case ORDER_STATUS.CANCEL_CONSUMER:
      case ORDER_STATUS.CANCEL_DRIVER:
      case ORDER_STATUS.PAYMENT_FAILED:
      case ORDER_STATUS.DELETED:
      default:
        return COLORS.LightRed;
    }
  },
};

export const ORDER_SERVICE_STATUS = {
  UNSERVICED: 'unserviced',
  SERVICED: 'serviced',
  CANCELED: 'canceled',
};

export const ORDER_SERVICE_STATUS_LABEL = {
  [ORDER_SERVICE_STATUS.UNSERVICED]: 'PENDING',
  [ORDER_SERVICE_STATUS.SERVICED]: 'SERVICED',
  [ORDER_SERVICE_STATUS.CANCELED]: 'CANCELED',
};

export const ORDER_SERVICE_STATUS_CHIP_COLOR = {
  [ORDER_SERVICE_STATUS.UNSERVICED]: 'info',
  [ORDER_SERVICE_STATUS.SERVICED]: 'success',
  [ORDER_SERVICE_STATUS.CANCELED]: 'error',
};
