import React from 'react';
import { useLoaderData, useRevalidator } from 'react-router-dom';
import api from 'api';
import AsyncButton from 'components/AsyncButton';
import { useVendor } from 'contexts/VendorContext';
import { useSnackbar } from 'notistack';
import { Box } from '@mui/material';

const OrderPendingView = () => {
  const order = useLoaderData();
  const { enqueueSnackbar } = useSnackbar();
  const { washVendor } = useVendor();
  const revalidator = useRevalidator();

  const handleInRoute = async () => {
    try {
      await api.put(`/wash-vendor-app/${washVendor._id}/order/${order._id}/in_route`);
      revalidator.revalidate();
    } catch (e) {
      enqueueSnackbar(e.response?.data?.message ?? 'Something went wrong', { variant: 'error' });
    }
  };
  return (
    <Box display="flex" justifyContent="center">
      <AsyncButton onClick={handleInRoute} variant="contained">
        NOTIFY ON MY WAY
      </AsyncButton>
    </Box>
  );
};

export default OrderPendingView;
