import React, { createContext, useContext, useState } from 'react';

const VendorContext = createContext({
  washVendor: undefined,
  setVendor: () => {},
});
export default VendorContext;

export const VendorProvider = ({ children }) => {
  const [washVendor, setVendor] = useState(undefined);

  return (
    <VendorContext.Provider
      value={{
        washVendor: washVendor,
        setVendor: setVendor,
      }}
    >
      {children}
    </VendorContext.Provider>
  );
};

export const useVendor = () => useContext(VendorContext);
