import React, { useRef, useState } from 'react';
import { ButtonBase, CircularProgress } from '@mui/material';
import { CameraAlt } from '@mui/icons-material';

const TakePicture = ({ value, onChange, label, sx, ...rest }) => {
  const fileRef = useRef();
  const [isLoading, setLoading] = useState(false);
  const handleChange = async e => {
    try {
      setLoading(true);
      await onChange(e.target.files[0]);
    } catch (e) {
      setLoading(false);
      throw e;
    } finally {
      setLoading(false);
    }
  };

  return value ? (
    <img src={value} width={75} height={75} style={{ borderRadius: '16px' }} />
  ) : (
    [
      <input
        type="file"
        onChange={handleChange}
        ref={fileRef}
        style={{ display: 'none' }}
        accept="image/png, image/jpeg"
      />,
      <ButtonBase
        disabled={isLoading}
        onClick={() => {
          fileRef.current.click();
        }}
        sx={{
          display: 'inline-block',
          textAlign: 'center',
          alignContent: 'center',
          borderRadius: '16px',
          minWidth: 75,
          height: 75,
          backgroundColor: '#DDD',
          color: '#666',
          boxShadow: '1px 3px 10px 1px rgba(0,0,0,0.05)',
          ...sx,
        }}
        {...rest}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <div>
              <CameraAlt style={{ fontSize: 40, marginTop: 8 }} />
            </div>
            <div>{label}</div>
          </>
        )}
      </ButtonBase>,
    ]
  );
};
export default TakePicture;
