import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

/*
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response?.status === 403 &&
      error.response?.data?.message ===
        "You are not permitted to perform this action."
    ) {

      window.location.href = "/login";

      return;
    }

    throw error;
  }
);
*/
export default api;
